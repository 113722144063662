import { MutableRefObject, RefCallback } from 'react';
import { Control, Controller, FieldPath } from 'react-hook-form';
import { LexicalEditor } from 'lexical';

import { ClassName, ID, IsDisabled } from '../../../types';
import { ProjectNanoID } from '../../../main/projects/projectsTypes';
import { TeamNanoID } from '../../../main/teams/teamsTypes';
import { TaskNanoID } from '../../../main/tasks/tasksTypes';

import { LexicalEditorFieldControl } from './LexicalEditorFieldControl';

export type LexicalFieldValueType = {
  text?: string;
  mentionIds?: ID[];
};

export type OnChangeLexicalFieldValue = (value: LexicalFieldValueType) => void;

export type LexicalFieldRefType = LexicalEditor;

export type LexicalFieldRef =
  | RefCallback<LexicalFieldRefType>
  | MutableRefObject<LexicalFieldRefType | null | undefined>;

interface LexicalEditorFieldProps<T> {
  autoFocus?: boolean;
  companyNanoId?: TeamNanoID;
  control: Control<T>;
  disabled?: IsDisabled;
  editorRef?: LexicalFieldRef;
  inputClassName?: ClassName;
  name: FieldPath<T>;
  onBlur?: (e: FocusEvent) => void;
  onFocus?: (e: FocusEvent) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  projectNanoId?: ProjectNanoID;
  taskNanoId?: TaskNanoID;
  withFormatting?: boolean;
  withoutCommands?: boolean;
  wrapperClassName?: ClassName;
}
function LexicalEditorField<T>({
  autoFocus = false,
  companyNanoId,
  control,
  disabled,
  editorRef,
  inputClassName,
  name,
  onBlur,
  onFocus,
  onKeyDown,
  projectNanoId,
  taskNanoId,
  withFormatting,
  withoutCommands,
  wrapperClassName
}: LexicalEditorFieldProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref } }) => (
        <LexicalEditorFieldControl
          autoFocus={autoFocus}
          companyNanoId={companyNanoId}
          disabled={disabled}
          editorRef={(eRef) => {
            ref(eRef);
            if (editorRef) {
              if (typeof editorRef === 'function') {
                editorRef(eRef);
              } else {
                editorRef.current = eRef;
              }
            }
          }}
          inputClassName={inputClassName}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          projectNanoId={projectNanoId}
          taskNanoId={taskNanoId}
          value={value as LexicalFieldValueType}
          withFormatting={withFormatting}
          withoutCommands={withoutCommands}
          wrapperClassName={wrapperClassName}
        />
      )}
    />
  );
}

export default LexicalEditorField;
