import { useState } from 'react';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import { TRANSFORMERS } from '@lexical/markdown';

import { ClassName, ID, IsDisabled } from '../../types';
import { ProjectNanoID } from '../../main/projects/projectsTypes';
import { TeamNanoID } from '../../main/teams/teamsTypes';
import { TaskNanoID } from '../../main/tasks/tasksTypes';

import { useLexicalEditorHelper } from './hooks/useLexicalEditorHelper';

import { ActionsPlugin } from './components/ActionsPlugin';
import { AutoFocusPlugin } from './components/AutoFocusPlugin';
import { CommandsPlugin } from './components/CommandsPlugin';
import { MarkdownConversationPlugin } from './components/MarkdownConversationPlugin';
import { ProjectTeamMentionsPlugin } from './components/ProjectTeamMentionsPlugin';
import {
  LexicalFieldRef,
  LexicalFieldValueType,
  OnChangeLexicalFieldValue
} from '../FormFields/LexicalEditorField/LexicalEditorField';

import { CheckPermissions } from '../CheckPermissions';

import { MessagesPermissions } from '../../main/messages/messagesConstants';

const Placeholder = () => {
  return <span />;
};

export interface LexicalEditorHelperProps {
  // config: Parameters<typeof LexicalComposer>['0']['initialConfig'];
  autoFocus?: boolean;
  companyNanoId?: TeamNanoID;
  disabled?: IsDisabled;
  editorRef?: LexicalFieldRef;
  inputClassName?: ClassName;
  name: string;
  onBlur?: (e: FocusEvent) => void;
  onChange?: OnChangeLexicalFieldValue;
  onFocus?: (e: FocusEvent) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  projectNanoId?: ProjectNanoID;
  taskNanoId?: TaskNanoID;
  value: LexicalFieldValueType;
  withFormatting?: boolean;
  withoutCommands?: boolean;
  wrapperClassName?: ClassName;
}

function LexicalEditorHelper({
  autoFocus = false,
  companyNanoId,
  disabled,
  editorRef,
  inputClassName,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  projectNanoId,
  taskNanoId,
  value,
  withFormatting = true,
  withoutCommands = false,
  wrapperClassName
}: LexicalEditorHelperProps) {
  const [initialMentionIds] = useState<ID[]>(value?.mentionIds);

  const { lexicalEditorConfig, handleChange } = useLexicalEditorHelper({
    disabled,
    onChange,
    value,
    inputClassName
  });

  return (
    <div
      id="editor-wrapper"
      // className="flex flex-1 focus-within:ring-4 focus-within:ring-blue-300 overflow-hidden rounded-md self-stretch cursor-text max-h-96"
      className={
        wrapperClassName ||
        'max-h-80 relative flex-1 sm:min-w-80 cursor-default'
      }
    >
      <div className="w-full h-full">
        <LexicalComposer initialConfig={lexicalEditorConfig}>
          {autoFocus && <AutoFocusPlugin />}
          <RichTextPlugin
            contentEditable={<ContentEditable name={name} />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <OnChangePlugin onChange={handleChange} ignoreSelectionChange />
          <ListPlugin />
          <LinkPlugin />
          <HistoryPlugin />
          {/*TODO: Move users plugins to props*/}
          {/*<CheckPermissions*/}
          {/*  action={MessagesPermissions.READ_MESSAGE_USER_MENTIONS}*/}
          {/*>*/}
          {/*  <UserMentionsPlugin />*/}
          {/*</CheckPermissions>*/}
          <CheckPermissions action={MessagesPermissions.READ_MESSAGE_COMMANDS}>
            {!withoutCommands && <CommandsPlugin />}
          </CheckPermissions>
          <ProjectTeamMentionsPlugin
            companyNanoId={companyNanoId}
            projectNanoId={projectNanoId}
            taskNanoId={taskNanoId}
            initialMentionIds={initialMentionIds}
          />
          {/* <MarkdownDoubleConversationPlugin value={value?.text} /> */}
          <MarkdownConversationPlugin withFormatting={withFormatting} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          <ActionsPlugin
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            value={value?.text}
          />
          <EditorRefPlugin editorRef={editorRef} />
        </LexicalComposer>
      </div>
    </div>
  );
}

export default LexicalEditorHelper;
